<template>
  <v-card :height="height" :width="width" elevation="0">
    <div ref="container" class="vue-bpmn-diagram-container" />
  </v-card>
</template>

<script>
import BpmnJS from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';

export default {
  name: 'BpmnDiagramComponent',

  props: {
    height: {
      type: String,
      required: true
    },

    width: {
      type: String,
      required: true
    },

    xml: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      diagramXML: null
    };
  },

  watch: {
    xml: function(val) {
      this.$emit('loading');
      this.getDiagram(val);
    },

    diagramXML: function(val) {
      this.bpmnViewer.importXML(val);
    }
  },

  mounted() {
    const container = this.$refs.container;
    let self = this;

    this.bpmnViewer = new BpmnJS({
      container: container
    });

    this.bpmnViewer.on('import.done', function(event) {
      const error = event.error;
      const warnings = event.warnings;

      if (error) {
        self.$emit('error', error);
      } else {
        self.$emit('shown', warnings);
      }

      self.bpmnViewer.get('canvas').zoom('fit-viewport');
    });

    if (this.xml) {
      this.getDiagram(this.xml);
    }
  },

  beforeDestroy() {
    this.bpmnViewer.destroy();
  },

  methods: {
    getDiagram(xml) {
      let self = this;
      self.diagramXML = xml;
    }
  }
};
</script>

<style>
.vue-bpmn-diagram-container {
  height: 100%;
  width: 100%;
}
</style>

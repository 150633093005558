<template>
  <div class="mt-3">
    <v-expansion-panels
      v-if="existsProcessDefinition()"
      v-model="panels"
      multiple
    >
      <v-expansion-panel
        v-for="(subprocess, i) in process_definition.eebpm_lego"
        :key="i"
      >
        <v-expansion-panel-header hide-actions class="panel__item--border">
          <span class="text-caption font-weight-bold">{{
            subprocess.leg_nam
          }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-for="(task, j) in subprocess.eebpm_own_task"
            :key="j"
            class="align-center my-0"
            :class="getBottomBorderStyle(j, subprocess.eebpm_own_task)"
          >
            <v-col cols="12" md="3">
              <span class="text-body-2">{{ task.task__label }}</span>
            </v-col>
            <v-col cols="12" md="2">
              <span class="text-body-2">{{ getTaskType(task.task_tip) }}</span>
            </v-col>
            <v-col cols="12" md="4">
              <div
                v-for="(feature, k) in task_features"
                :key="k"
                class="d-inline-flex"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="hasFeature(feature, task)"
                      class="mr-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getFeatureIcon(feature) }}
                    </v-icon>
                  </template>
                  <span>{{ getFeatureTooltip(feature) }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <v-combobox
                class="text-body-2"
                :color="colors.primary"
                dense
                :disabled="task.eebpm_dats_task === undefined"
                hide-details
                :item-color="colors.primary"
                item-text="dats_label"
                :items="task.eebpm_dats_task"
                outlined
                :value="getDefaultDataObject(task.eebpm_dats_task)"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-progress-linear v-else indeterminate />

    <v-btn
      v-if="existsProcessDefinition()"
      bottom
      :color="colors.primary"
      dark
      fab
      fixed
      right
      @click="toggleFloatingActionButtonIcon"
    >
      <v-icon>{{ getFloatingActionButtonIcon() }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import colors from '@/styles/colors.js';
import { mapGetters } from 'vuex';

export default {
  name: 'ProcessSummaryView',

  data() {
    return {
      colors: undefined,
      panels: [],
      title: 'Sumario de Proceso',
    };
  },

  computed: {
    ...mapGetters({
      icons: 'icons/getIcons',
      process_definition: 'SIRbpm_proc_grf/getProcessDefinition',
      task_features: 'SIRbpm_proc_grf/getTaskFeatures',
      task_types: 'SIRbpm_proc_grf/getTaskTypes',
    }),
  },

  async created() {
    this.colors = colors;

    await this.$store.dispatch('SIRbpm_proc_grf/get', {
      picproc__name: this.$store.state.SIRbpm_proc.proceso_en_seleccion,
    });
  },

  methods: {
    existsProcessDefinition() {
      return this.process_definition != undefined;
    },

    expandPanel() {
      this.process_definition.eebpm_lego.forEach((element, index) => {
        this.panels.push(index);
      });
    },

    getBottomBorderStyle(index, tasks) {
      return index < tasks.length - 1 ? 'panel__item--border' : '';
    },

    getDefaultDataObject(dataObject) {
      if (dataObject) {
        return dataObject[0].dats_label;
      }
    },

    getFeatureIcon(feature) {
      return this.icons.find((icon) => icon.key === feature).value;
    },

    getFeatureTooltip(feature) {
      return this.icons.find((icon) => icon.key === feature).text;
    },

    getFloatingActionButtonIcon() {
      if (this.panels.length === 0) {
        return this.icons.find((icon) => icon.key === 'expand').value;
      } else {
        return this.icons.find((icon) => icon.key === 'shrink').value;
      }
    },

    getTaskType(task_tip) {
      return this.task_types[task_tip];
    },

    hasFeature(feature, task) {
      return task[feature];
    },

    shrinkPanel() {
      this.panels = [];
    },

    toggleFloatingActionButtonIcon() {
      if (this.panels.length === 0) {
        this.expandPanel();
      } else {
        this.shrinkPanel();
      }
    },
  },
};
</script>
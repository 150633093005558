<template>
  <v-container fluid>
    <v-row v-for="user in grupo.eebpm_userg" :key="user.usr_cod">
      <v-col class="text-sm-left">
        {{ user.usr_name }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Listausuarios",
  props: {
    grupo: {
      type: Object,
      default() {
        return {};
      },
    },
    msg: {
      type: String,
      default: 'no leì el props'
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
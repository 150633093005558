<template>
  <v-dialog v-model="dialog" persistent max-width="360">
    <v-card height="185">
      <v-card-title>
        <span class="mb-3">{{ title }}</span>
        <v-progress-linear
          :color="color"
          :indeterminate="loading"
          rounded
          height="3"
        />
      </v-card-title>
      <v-card-text>
        <span class="d-flex justify-center mt-3">{{ text }}</span>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <slot name="buttons" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Dialog base en Integrity.
 * @author César Peñalosa
 * @version 1.0
 */
export default {
  name: 'BaseDialogComponent',

  props: {
    color: {
      type: String,
      default: 'transparent',
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
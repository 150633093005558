<template>
  <v-container fluid style="overflow-y: scroll">
    <section v-if="!formview">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <!-- <span class="font-weight-medium text-h6">Tareas {{ lego.type__lego }}</span> -->
            <span class="text-subtitle-1 font-weight-bold mt-2">{{
              $store.state.title
            }}</span>
            <div>
              <v-icon
                v-show="process.new == false && $store.state.grid"
                class="mx-2"
                @click="addTask()"
                >mdi-plus</v-icon
              >
              <base-button
                :icon-key="$store.state.SIRbpm_proc_grf.config.icon.key"
                :icon-text="$store.state.SIRbpm_proc_grf.config.icon.text"
                :icon-value="$store.state.SIRbpm_proc_grf.config.icon.value"
                @click="openSummary"
              ></base-button>
            </div>
          </div>

          <v-divider inset></v-divider>
        </v-col>
      </v-row>
      <v-row v-if="$store.state.grid">
        <v-col>
          <div>
            <v-data-table :headers="headers" :items="tasks" class="elevation-1">
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="editTask(item)">mdi-pencil-outline</v-icon>
                <v-icon @click="deleteTask(item)">mdi-trash-can-outline</v-icon>
                <v-icon @click="abrirFormulario(item)">mdi-dns</v-icon>
                <v-icon @click="abrirCondiciones(item)">mdi-rhombus</v-icon>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col v-show="taskview" sm="12" md="4">
          <v-row
            class="d-flex justify-space-between align-center pa-2 mr-2"
            style="background-color: #cf0021; color: white; height: 40px"
            ><span v-if="!preview">Tarea</span>
            <v-icon color="white" @click="closeTask"> mdi-close </v-icon></v-row
          >
          <section>
            <v-row class="pa-2 mr-2">
              <v-col cols="12">
                <v-text-field
                  v-model="task.task__label"
                  name="task__label"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col v-if="task.usr__cod_2 !== ''" cols="12">
                <v-text-field
                  v-model="task.usr__cod_2"
                  name="usr__cod_2"
                  label="Actores"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col v-else cols="12">
                <v-select
                  v-model="task.usr__cod"
                  :items="grupos"
                  :menu-props="{ maxHeight: '300' }"
                  label="Actores"
                  item-value="grp_id"
                  item-text="gpr_name"
                >
                  <template v-slot:item="data">
                    <v-list-item v-bind="data.attrs" v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title                          
                        >{{data.item.gpr_name}}</v-list-item-title>
                      </v-list-item-content>
                      <v-icon
                        v-if="data.item.numUsuarios > 0"
                        class="mx-2"
                        @click.stop="mostrarGrupo(data.item)"
                      >
                        mdi-eye
                      </v-icon>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="task.task_des"
                  name="task_des"
                  label="Descripción"
                ></v-textarea>
              </v-col>
              <v-col v-if="task.task_dur_2 !== ''" cols="6">
                <v-text-field
                  v-model="task.task_dur_2"
                  name="task_dur_2"
                  label="Duración"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col v-else cols="6">
                <v-text-field
                  v-model="task.task_dur"
                  name="task_dur"
                  label="Duración"
                  :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="task.pri_org"
                  name="pri_org"
                  :items="priorities"
                  label="Prioridad"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col
                v-if="lego.type__lego === 'Horizontal'"
                cols="12"
              >
                <v-row>
                  <v-col
                    cols="1"
                    class="d-flex align-center"
                  >
                    <v-icon
                      class="mx-2"
                      :class="{
                        'primary--text': task.time_con.con_rej === true
                      }"
                      @click="updateTimeCondition()"
                    >
                      mdi-clock-outline
                    </v-icon>
                  </v-col>

                  <v-col>
                    <v-select
                      v-if="task.time_con.con_rej === true"
                      v-model="task.time_con.task_nam_fin"
                      :items="tasksFinishList"
                      label="Tarea cuando el tiempo termina"
                      item-text="task__label"
                      item-value="task__name"
                      dense
                    />
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <!--subtasks task_stk-->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        v-tooltip="'subtareas'"
                        class="mx-2"
                        :class="{ 'primary--text': task.task_stk }"
                        @click="task.task_stk = !task.task_stk"
                        >mdi-family-tree</v-icon
                      >
                    </span>
                  </template>
                  <span>Puede tener subtareas</span>
                </v-tooltip>
                <!--reasignar task_res-->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        class="mx-2"
                        :class="{ 'primary--text': task.task_res }"
                        @click="task.task_res = !task.task_res"
                        >mdi-account-switch</v-icon
                      >
                    </span>
                  </template>
                  <span>Se puede reasignar la task</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        class="mx-2"
                        :class="{
                          'primary--text': task.task_tip === 2
                        }"
                        @click="changeTaskType(task.task_tip)"
                        >mdi-webhook</v-icon
                      >
                    </span>
                  </template>
                  <span>task_tip</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </section>
          <v-btn block color="primary" elevation="2" x-large @click="saveTask()"
            >Guardar</v-btn
          >
          <v-row v-if="preview" class="pa-4">
            <draggable
              v-model="dataslotsForm"
              draggable=".item"
              style="width: 100%"
              @change="formOrder"
            >
              <div
                v-for="element in dataslotsForm"
                :key="element.dats_label"
                class="item divItemFormu my-2"
              >
                <v-text-field :label="element.dats_label"></v-text-field>
              </div>
            </draggable>
          </v-row>
        </v-col>
      </v-row>
      <process-summary v-if="$store.state.SIRbpm_proc_grf.config.state" />
      <v-dialog v-model="dialogUsers" max-width="400">
        <v-card>
          <v-card-actions
            style="background-color: #cf0021; color: white; height: 40px"
          >
            <v-spacer>{{ grupo.gpr_name }}</v-spacer>
            <v-icon color="white" @click="dialogUsers = false">
              mdi-close
            </v-icon>
          </v-card-actions>
          <v-card-text>
            <v-container>
              <ListaUsuarios :grupo="grupo"></ListaUsuarios>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </section>
    <section v-if="formview">
      <FormInicio
        :url-services="urlServices"
        :process="process"
        :user="user"
        :fiid="fiid"
        :task-name="task.task__name"
        :task-label="task.task__label"
        :lego="lego"
        @updatingTask="updateTask"
      />
    </section>
    <base-snackbar
      :color="colors.primary"
      :enabled="snackbar.enabled"
      :text="snackbar.text"
      @close="closeSnackbar"
    ></base-snackbar>
  </v-container>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseSnackbar from '@/components/Base/BaseSnackbar.vue';
import colors from '@/styles/colors.js';
import FormInicio from '@/components/FormularioInicioTareas.vue';
import ListaUsuarios from '@/components/ListaUsuarios.vue';
import ProcessSummary from '@/views/ProcessSummary.vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'TasksOfLego',

  components: {
    BaseButton,
    BaseSnackbar,
    ListaUsuarios,
    FormInicio,
    ProcessSummary
  },

  props: {
    lego: {
      type: Object,
      default() {
        return {};
      }
    },
    urlServices: {
      type: String,
      default: '',
      required: true
    },
    process: {
      type: Object,
      default() {
        return {};
      }
    },
    user: {
      type: String,
      default: '',
      required: true
    },
    fiid: {
      type: String,
      default: '',
      required: true
    },
  },

  data() {
    return {
      colors: undefined,

      snackbar: {
        enabled: false,
        text: ''
      },

      tasks: [],
      headers: [
        {
          text: 'Tarea',
          align: 'start',
          sortable: false,
          value: 'task__label'
        },
        {
          text: 'Descripción',
          align: 'start',
          sortable: false,
          value: 'task_des'
        },
        { text: '', align: 'start', sortable: false, value: 'actions' }
      ],
      preview: false,
      formview: false,
      taskview: false,
      conditionView: false,
      task: {
        cia__nit: '',
        pos__lego: this.lego.pos__lego,
        pri_org: '',
        proc__name: this.process.proc__name,
        task__label: '',
        task__name: '',
        task__post: 0,
        task__state: '',
        task__type: '',
        task_ace: '',
        task_bin: false,
        task_brel: false,
        task_cxh: false,
        task_des: '',
        task_dur: '',
        task_mnc: true, // que es esto
        task_msg: false,
        task_res: false,
        task_sha: false,
        task_stk: false,
        task_bxt: true, // que es esto
        task_docs: true, // que es esto
        task_dxt: true, // que es esto
        task_link: false, // que es esto
        task_out: false, // que es esto
        task_tip: 2,
        user__type: '',
        usr__cod: '',
        time_con: {
          piindicador: 0,
          con_id: 0,
          con_rej: false,
          con_usu: '',
          con_val: '',
          proc__dats: '',
          proc__name: this.process.proc__name,
          task_nam_fin: '',
          task_nam_ini: ''
        }
      },
      dialogUsers: false,
      grupos: [{
        piindicador: null,
        cia_nit: '',
        gpr_des: '',
        gpr_name: '',
        grp_typ: null,
        usr_cod: '',
        grp_est: null,
        totisr: null,
        grp_des: '',
        grp_id: null,
        eebpm_userg: [],
        numUsuarios: 8,
        listaVisible: false
      }],
      grupo: {},
      rules: [
        value => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || 'Solo números enteros positivos';
        }
      ],
      nextTask: 0,
      priorities: [],
      dataslots: [],
      task_nam_fin: '',
      tasksFinishList: [{
        piindicador: null,
        cia__nit: '',
        pos__lego: null,
        type__lego: '',
        proc__name: '',
        task__label: '',
        task__name: null,
        task__post: null,
        task__state: '',
        task__type: '',
        task_bin: false,
        task_brel: false,
        task_bxt: true,
        task_cxh: false,
        task_des: '',
        task_docs: true,
        task_dur: '',
        task_dur_2: '',
        task_dur_org: '',
        task_dxt: true,
        task_link: false,
        task_mnc: false,
        task_msg: false,
        task_res: false,
        task_sha: false,
        task_stk: false,
        task_tip: null,
        user__type: null,
        usr__cod: '',
        usr__cod_2: '',
        usr_cod_org: '',
        pri_org: '',
        task_ace: '',
        task_ace_2: '',
        task_ace_org: '',
        task_out: false,
        cnd_fil_id: null,
        task_nam_fin: ''
    }],
      inputMutiple: ''
    };
  },

  computed: {
    ...mapGetters({
      icons: 'icons/getIcons'
    }),

    isCallTasks() {
      return this.$store.state.SICUDbpm_own_task.config.response
    },
  },

  watch: {
    lego() {
      this.getTasks();
      this.cleanTask();
      this.preview = false;
      this.formview = false;
      this.taskview = false;
    },

    task() {
      this.getTasksFinishList();
    },

    inputMutiple() {
      alert('hola');
    },

    isCallTasks(value) {
      if (!value) {
        this.getTasks();
        this.$store.commit('SICUDbpm_own_task/SET_RESPONSE', !value);
      };
    }
  },
  mounted: function() {
    this.getTasks();
    this.getGroups();
    this.getMessages();
    // this.getDataslots();
  },

  created() {
    this.colors = colors;

    this.$store.commit(
      'SIRbpm_proc/ESTABLECER_PROCESO_EN_SELECCION',
      this.task.proc__name
    );

    this.$store.dispatch('SIRbpm_proc_grf/updateConfig', {
      state: false,
      icon: this.icons.find(icon => icon.key === 'monitor-eye')
    });

    this.$store.dispatch('toggleGrid', true);
  },

  methods: {
    closeSnackbar(event) {
      this.snackbar.enabled = event;
      this.$store.dispatch('messaging/setMessage', '');
    },

    openSummary(event) {
      if (event === 'monitor-eye') {
        this.$store.dispatch('toggleGrid', false);

        this.$store.dispatch('SIRbpm_proc_grf/updateConfig', {
          state: true,
          icon: this.icons.find(icon => icon.key === 'monitor-off')
        });

        this.$store.dispatch('toggleTitle', {
          currentBlockTitle: undefined,
          type: 'summary',
          text: undefined
        });
      } else {
        this.$store.dispatch('toggleGrid', true);

        this.$store.dispatch('SIRbpm_proc_grf/updateConfig', {
          state: false,
          icon: this.icons.find(icon => icon.key === 'monitor-eye')
        });

        this.$store.dispatch('toggleTitle', {
          currentBlockTitle: undefined,
          type: 'block',
          text: undefined
        });
      }
    },

    async abrirCondiciones(item) {
      this.taskview = false;
      this.cleanTask();

      await this.$store.dispatch('SIRbpm_own_task/get', {
        picproc__name: item.proc__name,
        pictask__name: '*',
        piipos_lego: item.pos__lego,
        piitask_fin: 0
      });

      this.$store.dispatch('SIRbpm_own_task/get', {
        picproc__name: item.proc__name,
        pictask__name: item.task__name,
        piipos_lego: item.pos__lego,
        piitask_fin: 1
      });

      await this.$store.dispatch('SIRbpm_dats_proc/get', {
        picproc__name: item.proc__name
      });

      if (this.$store.state.SIRbpm_dats_proc.config.response) {
        this.snackbar.text = this.$store.state.messaging.message;
        this.snackbar.enabled = true;
      }

      this.$store.dispatch(
        'SIRbpm_own_task/establecerTareaEnSeleccion',
        item.task__name
      );

      this.$store.dispatch('establecerDrawerRight', true);
    },

    closeTask() {
      this.taskview = false;
    },
    getMessages() {
      let jsonService = {
        dssic_msg: {
          eesic_msg: [
            {
              msg_cod: 900116
            }
          ],
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'POST',
        url: this.urlServices + 'web/Base/BaseIntegrity/SIRsic_msg',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dssic_msg.eeEstados[0].Estado == 'OK') {
            let stringPriorities = _.find(
              response.data.dssic_msg.eesic_msg,
              function(o) {
                return o.msg_cod == 900116;
              }
            );
            let arrayPriorities = stringPriorities.msg_des.split(',');
            let arrayReturn = [];
            for (let i = 0; i < arrayPriorities.length; i++) {
              let obj = new Object();
              obj.value = arrayPriorities[i].split(';')[0];
              obj.text = arrayPriorities[i].split(';')[1];
              arrayReturn.push(obj);
            }

            currentObj.priorities = arrayReturn;
          } else {
            console.error(response.data.dssic_msg.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    getTasks() {
      let jsonService = {
        dsbpm_own_task: {
          eeSIRbpm_own_task: [
            {
              piccia__nit: '*',
              picusr__cod: '*',
              picproc__name: this.process.proc__name,
              pictask__name: '*',
              picusuario: '*',
              piipos_lego: this.lego.pos__lego
            }
          ],
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'POST',
        url: this.urlServices + 'web/Procesos/SIRbpm_own_task',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsbpm_own_task.eeEstados[0].Returnid === 98) {
            currentObj.tasks = [];
          } else if (response.data.dsbpm_own_task.eeEstados[0].Estado == 'OK') {
            for (
              let i = 0;
              i < response.data.dsbpm_own_task.eebpm_own_task.length;
              i++
            ) {
              
              if(response.data.dsbpm_own_task.eebpm_own_task[i].usr__cod !== ""){
                response.data.dsbpm_own_task.eebpm_own_task[
                  i
                ].usr__cod = parseInt(
                  response.data.dsbpm_own_task.eebpm_own_task[i].usr__cod
                );
              }else{
                response.data.dsbpm_own_task.eebpm_own_task[
                  i
                ].usr__cod = 0;
              }
            }
            if (currentObj.lego.type__lego === 'Horizontal') {
              for (
                let i = 0;
                i < response.data.dsbpm_own_task.eebpm_own_task.length;
                i++
              ) {
                currentObj.setTimeCodition(
                  response.data.dsbpm_own_task.eebpm_own_task[i]
                );
              }
            }
            currentObj.tasks = _.sortBy(
              response.data.dsbpm_own_task.eebpm_own_task,
              ['task__post']
            );
          } else {
            (currentObj.tasks = []),
              console.error(response.data.dsbpm_own_task.eeEstados[0].Estado);
          }

          currentObj.nextTask = currentObj.taskPos();
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    getTasksFinishList() {
      let jsonService = {
        dsbpm_own_task: {
          eeSIRbpm_own_task: [
            {
              piccia__nit: '*',
              picusr__cod: '*',
              picproc__name: this.process.proc__name,
              pictask__name: this.task.task__name,
              picusuario: '*',
              piipos_lego: 0,
              piitask_fin: 1
            }
          ],
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'POST',
        url: this.urlServices + 'web/Procesos/SIRbpm_own_task',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsbpm_own_task.eeEstados[0].Returnid === 98) {
            currentObj.tasksFinishList = [];
          } else if (response.data.dsbpm_own_task.eeEstados[0].Estado == 'OK') {
            response.data.dsbpm_own_task.eebpm_own_task.forEach((element) => {
                currentObj.tasksFinishList.push(element);
              })
          } else {
            (currentObj.tasksFinishList = []),
              console.error(response.data.dsbpm_own_task.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    abrirFormulario(task) {
      this.task = task;
      this.formview = true;
    },
    addTask() {
      this.taskview = true;
      this.cleanTask();
    },
    editTask(task) {
      this.taskview = true;
      this.task = Object.assign({}, task);
    },
    setTimeCodition(task) {
      if (task.eebpm_con === undefined) {
        task.time_con = {
          piindicador: 0,
          con_id: 0,
          con_rej: false,
          con_usu: '',
          con_val: '',
          proc__dats: '',
          proc__name: task.proc__name,
          task_nam_fin: '',
          task_nam_ini: task.task__name
        };
      } else {
        let timeCondition = task.eebpm_con.find(
          condition => condition.con_rej === true
        );

        if (timeCondition !== undefined) {
          task.time_con = { ...timeCondition };
        } else {
          task.time_con = {
            piindicador: 0,
            con_id: 0,
            con_rej: false,
            con_usu: '',
            con_val: '',
            proc__dats: '',
            proc__name: task.proc__name,
            task_nam_fin: '',
            task_nam_ini: task.task__name
          };
        }
      }
    },
    updateTimeCondition() {
      this.task.time_con.con_rej = !this.task.time_con.con_rej;
      if (!this.task.time_con.con_rej) {
        this.task.time_con.task_nam_fin = '';
        this.task.time_con.con_val = '';
      }
    },
    getGroups() {
      let jsonService = {
        dsSIRbpm_gruser: {
          SIRbpm_gruser: [
            {
              nombre: '*',
              nit: '*',
              grp_typ: 2,
              usr_cod: '*',
              grp_est: '0',
              incuser: true
            }
          ],
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'post',
        url: this.urlServices + 'web/Procesos/SIRbpm_gruser',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSIRbpm_gruser.eeEstados[0].Estado == 'OK') {
            response.data.dsSIRbpm_gruser.eebpm_grp_fe.forEach((element) => {
              currentObj.grupos.push(element);
            })
          } else {
            console.error(response.data.dsSIRbpm_gruser.eeEstados[0].Estado);
          }

          currentObj.grupos.forEach(function(element) {
            if (
              element.eebpm_userg != undefined &&
              element.eebpm_userg.length > 0
            ) {
              element.numUsuarios = element.eebpm_userg.length;
            } else {
              element.numUsuarios = 0;
            }
            element.listaVisible = false;
          }, this);
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    mostrarGrupo(grupoSel) {
      this.dialogUsers = true;
      this.grupo = grupoSel;
    },

    saveTask() {
      const method = this.task.task__name !== '' ? 'PUT' : 'POST';

      const jsonService = {
        dsbpm_own_task: {
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eebpm_own_task: [
            { ...(this.lego.type__lego === 'Horizontal' ? this.processHorizontalTask() : this.processVerticalTask()) }
          ]
        }
      };

      if (method === 'POST') {
        jsonService.dsbpm_own_task.eebpm_own_task[0].task__post = this.nextTask;
      }

      this.axios({
        method: method,
        url: `${this.urlServices}web/Procesos/SICUDbpm_own_task`,
        data: jsonService
      })
        .then(response => {
          const estados = response.data.dsbpm_own_task.eeEstados;
          if (estados.length > 0 && estados[0].Estado === 'OK') {
            const taskSaved = { ...this.task, ...response.data.dsbpm_own_task.eebpm_own_task[0] };
            taskSaved.usr__cod = parseInt(taskSaved.usr__cod);
            this.task = taskSaved;
          } else {
            console.error(estados.length > 0 ? estados[0].Estado : 'Error desconocido');
          }
          this.getTasks();
        })
        .catch(error => {
          console.error(error);
        });
    },

    processHorizontalTask() {
      const taskToSave = { ...this.task };

      if (this.task.time_con.task_nam_fin !== '' && taskToSave.time_con.con_rej) {
        if (taskToSave.eebpm_con) {
          const index = taskToSave.eebpm_con.findIndex((condition) => condition.con_rej);

          if (index !== -1) {
            taskToSave.eebpm_con[index] = { ...taskToSave.time_con };
          } else {
            taskToSave.eebpm_con.push(taskToSave.time_con);
          }
        } else {
          taskToSave.eebpm_con = [];
          taskToSave.eebpm_con.push(taskToSave.time_con);
        }
      } else {
        const isCondition = taskToSave?.eebpm_con?.some((condition) => condition.con_rej === false);
        if(isCondition) {
          const currentCondition = taskToSave.eebpm_con.filter((condition) => condition.con_rej === false);
          taskToSave.eebpm_con = currentCondition;
        } else {
          delete taskToSave.eebpm_con;
        }
      }

      try {
        delete taskToSave.time_con;
      } catch (error) {
        console.error('No se pudo encontrar el nodo taskToSave.time_con');
      }

      return taskToSave;
    },

    processVerticalTask() {
      const taskToSave = { ...this.task };
      delete taskToSave.time_con;

      return taskToSave;
    },


    taskPos() {
      let pos = this.lego.pos__lego * 100;
      for (let i = 0; i < this.tasks.length; i++) {
        // console.log(this.tasks[i].task__post, pos);
        if (this.tasks[i].task__post > pos) {
          pos = this.tasks[i].task__post;
        }
      }
      return pos + 1;
    },
    changeTaskType(tipo) {
      if (tipo === 1) {
        this.task.task_tip = 2;
      }

      if (tipo === 2) {
        this.task.task_tip = 1;
      }
    },
    deleteTask(task) {
      let taskToDelete = { ...task };
      delete taskToDelete.time_con;

      let method = 'DELETE';

      let jsonService = {
        dsbpm_own_task: {
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eebpm_own_task: []
        }
      };

      jsonService.dsbpm_own_task.eebpm_own_task[0] = taskToDelete;

      let currentObj = this;

      this.axios({
        method: method,
        url: this.urlServices + 'web/Procesos/SICUDbpm_own_task',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsbpm_own_task.eeEstados[0].Estado == 'OK') {
            console.log(response.data.dsbpm_own_task.eeEstados[0].Estado);
          } else {
            console.error(response.data.dsbpm_own_task.eeEstados[0].Estado);
          }
          currentObj.getTasks();
          currentObj.cleanTask();
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    cleanTask() {
      this.task = {
        cia__nit: '',
        pos__lego: this.lego.pos__lego,
        pri_org: '',
        proc__name: this.process.proc__name,
        task__label: '',
        task__name: '',
        task__post: 0,
        task__state: '',
        task__type: '',
        task_ace: '',
        task_bin: false,
        task_brel: false,
        task_cxh: false,
        task_des: '',
        task_dur: '',
        task_dur_2: '',
        task_mnc: true, // que es esto
        task_msg: false,
        task_res: false,
        task_sha: false,
        task_stk: false,
        task_time: false,
        task_bxt: true, // que es esto
        task_docs: true, // que es esto
        task_dxt: true, // que es esto
        task_link: false, // que es esto
        task_out: false, // que es esto
        task_tip: 2,
        user__type: '',
        usr__cod: '',
        usr__cod_2: '',
        time_con: {
          piindicador: 0,
          con_id: 0,
          con_rej: false,
          con_usu: '',
          con_val: '',
          proc__dats: '',
          proc__name: this.process.proc__name,
          task_nam_fin: '',
          task_nam_ini: ''
        }
      };
    },
    getDataslots() {
      /**No se usa porque se quito lo de condiciones */
      let jsonService = {
        dsSIRbpm_dats_proc: {
          eeSIRbpm_dats_proc: [
            {
              picproc__name: this.process.proc__name,
              picproc__dats: '*',
              pictdat__user: '*',
              pildats__sis: false
            }
          ],
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'post',
        url: this.urlServices + 'web/Procesos/SIRbpm_dats_proc',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSIRbpm_dats_proc.eeEstados[0].Estado == 'OK') {
            currentObj.dataslots =
              response.data.dsSIRbpm_dats_proc.eebpm_dats_proc;
          } else {
            console.error(
              response.dsSIRbpm_dats_proc.dsSIRbpm_form_task.eeEstados[0].Estado
            );
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    getOperators(cond) {
      /**No se usa porque se quito lo de condiciones */
      /**No se usa porque se quito lo de condiciones */
      let dataslot = _.find(this.dataslots, function(o) {
        return o.proc__dats == cond.proc__dats;
      });
      let method = 'POST';

      let jsonService = {
        dsSIRbpm_opr: {
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          SIRbpm_opr: [
            {
              picproc__name: '',
              picdat__type: ''
            }
          ]
        }
      };

      jsonService.dsSIRbpm_opr.SIRbpm_opr[0].picproc__name = '*';
      jsonService.dsSIRbpm_opr.SIRbpm_opr[0].picdat__type = dataslot.tdat__user;
      cond.type = this.defineType(dataslot.tdat__user);

      this.axios({
        method: method,
        url: this.urlServices + 'web/Procesos/SIRbpm_opr',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSIRbpm_opr.eeEstados[0].Estado == 'OK') {
            cond.operatorList = response.data.dsSIRbpm_opr.eebpm_opr;
          } else {
            console.error(response.data.dsSIRbpm_opr.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    defineType(condType) {
      /**No se usa porque se quito lo de condiciones */
      console.log('condType', condType);
      switch (condType.toLowerCase()) {
        case 'texto':
          return 'text';
        case 'fecha':
          return 'date';
        case 'entero':
          return 'number';
        case 'decimal':
          return 'number';
        default:
          return 'text';
      }
    },
    saveCondition(cond) {
      /**No se usa porque se quito lo de condiciones */
      let obj = Object.assign({}, cond);
      (obj.task_nam_ini = this.task.task__name), delete obj.operatorList;
      delete obj.type;

      let method = 'POST';

      if (cond.con_id > 0) {
        method = 'PUT';
      }

      let jsonService = {
        dsSICUDbpm_con: {
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eebpm_con: []
        }
      };

      if (obj.con_id != 0) {
        method = 'PUT';
      }

      jsonService.dsSICUDbpm_con.eebpm_con[0] = obj;

      let currentObj = this;

      this.axios({
        method: method,
        url: this.urlServices + 'web/Procesos/SICUDbpm_con',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSICUDbpm_con.eeEstados[0].Estado == 'OK') {
            currentObj.condiciones[0].con_id =
              response.data.dsSICUDbpm_con.eebpm_con[0].con_id;
            // console.log(response.data.dsSICUDbpm_con.eeEstados[0].Estado);
          } else {
            console.error(response.data.dsSICUDbpm_con.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    getConditions() {
      /**No se usa porque se quito lo de condiciones */
      let method = 'POST';
      let jsonService = {
        dsSIRbpm_con: {
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eeSIRbpm_con: [
            {
              picproc__name: this.process.proc__name,
              pilcon_rej: '?',
              piicon_id: '?',
              pictask_nam_ini: this.task.task__name,
              pictask_nam_fin: '*',
              picproc__dats: '*',
              piccon_tip: '*',
              picusuario: '*'
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: method,
        url: this.urlServices + 'web/Procesos/SIRbpm_con',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSIRbpm_con.eeEstados[0].Returnid === 98) {
            currentObj.cleanConditions();
          } else if (response.data.dsSIRbpm_con.eeEstados[0].Estado == 'OK') {
            currentObj.condiciones[0] = response.data.dsSIRbpm_con.eebpm_con[0];
            for (let i = 0; i < currentObj.condiciones.length; i++) {
              currentObj.condiciones[i].operatorList = currentObj.getOperators(
                currentObj.condiciones[i]
              );
            }
          } else {
            console.error(response.data.dsSIRbpm_con.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    cleanConditions() {
      /**No se usa porque se quito lo de condiciones */      
      this.condiciones[0] = {
        con_rej: false,
        con_tip: '',
        con_val: '',
        proc__dats: '',
        task_nam_fin: '',
        type: 'Texto',
        proc__name: this.process.proc__name,
        task_nam_ini: '',
        operatorList: [],
        con_id: 0
      };
    },
    updateTask(newValue) {
      this.getTasks();
      this.formview = newValue; // 3.Updating
    },
    establecerTareaDeCondicion(event) {
      this.$store.dispatch('SIRbpm_own_task/establecerInputMultiple', event);
    }
  }
};
</script>

<style scoped>
.v-divider {
  margin-left: 0px !important;
  border-width: thin !important;
  border-color: #cf0021 !important;
  max-width: 100% !important;
  background-color: #cf0021 !important;
}

.divItemFormu {
  width: 90%;
  height: 80px;
  /* border-style: solid;
  border-color: #cf0021;*/
  cursor: move;
}
</style>